import React from 'react';
import AppImages1 from '../assets/appImages1.png';
import Technology from '../assets/technology.png';
import Sharing from '../assets/sharing.png';
import Network from '../assets/network.png';
import Membership from '../assets/membership.png';
import Repair from '../assets/repair.png';
import '../App.css';

class HowSteps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
  step2Header : "Tenant Activation",
  step2:"Tenants and residents can quickly and conveniently activate chargers through the Gridspot app, enabling them to start charging their vehicles in seconds. With an intuitive and user-friendly interface, the app guides users step-by-step, making setup and activation a breeze. Gridspot’s app seamlessly handles everything from account creation and payment processing to ongoing support. By offering a reliable and streamlined charging experience, we ensure that residents can focus on what matters most—charging their vehicles without any hassle.",
 step3Header : "Ongoing Maintenace & Support",
 step3: "At Gridspot, we handle everything to keep your EV chargers running smoothly. From routine maintenance and necessary repairs to software and hardware upgrades, our team ensures that every charger performs at its best. Property managers can enjoy peace of mind knowing that Gridspot continuously monitors each unit to detect and address any issues proactively, maximizing reliability and minimizing downtime. With our comprehensive support, you don’t need to worry about upkeep—just let us take care of the details, so your tenants and residents can count on a seamless charging experience every time.",


}

  }

  componentDidMount() {
   //
  }


  componentWillUnmount() {
  }

  render() {
    return (

   this.props.mobile ? 
 
    <div>
      <div style={{display:'block',paddingTop:'0px'}}> 
        <div style={{paddingTop:'40px',textAlign:'left'}}>
           <span style={{fontSize:'30px',fontWeight:'600',textAlign:'left'}}> {this.step2Header} </span> <br/> <br/><br/><br/>
           <span style={{color: '#686868',fontSize: '20px',fontWeight:'400',lineHeight:'30.7px'}}>
	     {this.state.step2}
            </span> 
        </div> <br/><br/>
        <div style={{}}>
          <center>  <img src={AppImages1} style={{objectFit:'contain',height:'60%',width:'60%'}}/>  </center>
        </div> <br/><br/><br/>
      </div> 
      <div style={{textAlign:'left',width:'100%',paddingBottom:'30px'}}>
      <span style={{fontSize:'30px',fontWeight:'600'}}> {this.state.step3Header} </span> <br/> <br/>
       <span style={{color: '#686868',fontSize: '20px',fontWeight:'400',lineHeight:'30.7px'}}>
      {this.state.step3}
       </span>  
       </div> 
      <div> 
          <center>

         <img src={Repair} style={{borderRadius:'30px',objectFit:'contain',width:'70%',height:'70%'}}/> 
          </center>
       </div>
     </div>
   
   :


    <div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',maxWidth:'2400px',height:'1000px',paddingTop:'80px'}}>
        <div style={{flex:'1',paddingTop:'150px',textAlign:'left'}}>
           <span style={{fontSize:'58px',fontWeight:'600',textAlign:'left'}}> {this.state.step2Header} </span> <br/> <br/><br/><br/>
           <span style={{color: '#686868',fontSize: '27px',fontWeight:'400',lineHeight:'30.7px'}}>
	      {this.state.step2}
            </span> 
        </div>
        <div style={{flex:'1',justifyContent:'right',textAlign:'right'}}>
           <img src={AppImages1} style={{objectFit:'contain',marginTop:'130px',height:'80%',width:'100%'}}/> 
        </div>
      </div>
      <div style={{textAlign:'left',width:'100%',paddingBottom:'30px',marginTop:'40px'}}>
      <span style={{fontSize:'56px',fontWeight:'600'}}> {this.state.step3Header} </span> <br/> <br/>
       <span style={{color: '#686868',fontSize: '27px',fontWeight:'400',lineHeight:'30.7px'}}>
      {this.state.step3}
       </span>  
       </div>
      <div style={{width:'100%',flexWrap:'wrap',display:'flex',justifyContent:'center'}}> 
          <div> <center>
         <img src={Repair} style={{borderRadius:'30px',objectFit:'contain',width:'70%',height:'70%'}}/> </center>
          </div>
      </div>
     </div>
    )
  }
}

export default HowSteps;
