import React from 'react';
import '../App.css';
import Plus from '../assets/plus'
import Minus from '../assets/minus'
import QuestionBox from './QuestionBox'

class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
   //
  }


  componentWillUnmount() {
  }

  render() {
    return (
    <div style={{marginTop: this.props.mobile ? '30px' : '80px'}}>
      <div style={{textAlign:'left',paddingBottom:'30px'}}>
         <span style={{fontSize:this.props.mobile ? '30px' : '56px',fontWeight:'600'}}> Any More Questions? </span> <br/> <br/>
         <span style={{color: '#686868',fontSize: this.props.mobile ? '20px' : '24px',fontWeight:'400',lineHeight:'30.7px'}}>
            Let's get started and power up your journey towards a greener future today!
          </span>  
      </div>
      <div style={{display:'flex',flexDirection:'column',justifyContents:'space-between'}}>
       <QuestionBox question="How Do Tenants Activate the Charger?" answer="After downloading the app and registering an account you can immediately access the chargers in a desired location and reserve the charging time you need (all major credit cards are accepted)." mobile={this.props.mobile}/>
        <QuestionBox question="What Are The Prices & Fees?" answer="Please contact us for our latest rate information" mobile={this.props.mobile}/>
  <QuestionBox question="What Types of EV Chargers Do You Offer" answer="We currently are offering level 2 J1772 or NACS 7 kWh/11 kWh (240V) chargers and accept all major credit cards. We also have 60 kW fast chargers (three phase input) available as well" mobile={this.props.mobile}/>
       <QuestionBox question="What are the Benefits of Installing EV Chargers on My Property?" answer="You can benefit by collecting additional revenue from charging, as well as provide your customers/tenants a valuable ammenity that is widely in demand. The IEA predicts that half of all cars sold globally will be electric by 2035!" mobile={this.props.mobile}/>
      </div>
   </div>
    )
  }
}

export default Questions;
