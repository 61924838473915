import React from 'react';

const svg = () => {
return (
<svg width="858" height="858" viewBox="0 0 858 858" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="Oval" opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M169.359 488.631C456.745 887.056 1012.71 977.071 1411.13 689.685C1809.56 402.299 1899.57 -153.661 1612.19 -552.087C1324.8 -950.512 768.839 -1040.53 370.413 -753.141C-28.0122 -465.755 -118.027 90.2055 169.359 488.631ZM412.669 313.13C222.209 49.0812 281.865 -319.371 545.914 -509.831C809.963 -700.291 1178.42 -640.636 1368.88 -376.586C1559.34 -112.537 1499.68 255.915 1235.63 446.376C971.581 636.836 603.129 577.18 412.669 313.13Z" fill="url(#paint0_linear_1_1445)" fill-opacity="0.4"/>
<defs>
<linearGradient id="paint0_linear_1_1445" x1="1612.19" y1="-552.087" x2="169.359" y2="488.631" gradientUnits="userSpaceOnUse">
<stop stop-color="#23AAE1"/>
<stop offset="1" stop-color="#7723E1"/>
</linearGradient>
</defs>
</svg>
);

}


export default svg;
