import React from 'react';

const svg = () => {
  return (
<svg width="157" height="320" viewBox="0 0 157 320" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.1">
<mask id="path-1-inside-1_1_1463" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.11568 183.053C1.35748 183.053 -1.0573 179.062 0.686489 175.733L91.3259 2.68784C93.7504 -1.94085 100.755 -0.217433 100.755 5.00778V131.535C100.755 134.296 102.994 136.535 105.755 136.535H151.885C155.643 136.535 158.058 140.526 156.314 143.855L65.6743 316.9C63.2499 321.529 56.2451 319.805 56.2451 314.58V188.053C56.2451 185.291 54.0066 183.053 51.2451 183.053H5.11568Z"/>
</mask>
<path d="M65.6743 316.9L63.9026 315.972L65.6743 316.9ZM0.686489 175.733L-1.08519 174.805L0.686489 175.733ZM89.5542 1.75985L-1.08519 174.805L2.45817 176.661L93.0976 3.61583L89.5542 1.75985ZM102.755 131.535V5.00778H98.7551V131.535H102.755ZM151.885 134.535H105.755V138.535H151.885V134.535ZM67.446 317.828L158.085 144.783L154.542 142.927L63.9026 315.972L67.446 317.828ZM54.2451 188.053V314.58H58.2451V188.053H54.2451ZM5.11568 185.053H51.2451V181.053H5.11568V185.053ZM58.2451 188.053C58.2451 184.187 55.1111 181.053 51.2451 181.053V185.053C52.902 185.053 54.2451 186.396 54.2451 188.053H58.2451ZM63.9026 315.972C62.4479 318.749 58.2451 317.715 58.2451 314.58H54.2451C54.2451 321.895 64.0518 324.308 67.446 317.828L63.9026 315.972ZM151.885 138.535C154.139 138.535 155.588 140.929 154.542 142.927L158.085 144.783C160.527 140.122 157.146 134.535 151.885 134.535V138.535ZM98.7551 131.535C98.7551 135.401 101.889 138.535 105.755 138.535V134.535C104.098 134.535 102.755 133.192 102.755 131.535H98.7551ZM-1.08519 174.805C-3.52649 179.466 -0.145802 185.053 5.11568 185.053V181.053C2.86076 181.053 1.4119 178.658 2.45817 176.661L-1.08519 174.805ZM93.0976 3.61583C94.5523 0.838578 98.7551 1.87269 98.7551 5.00778H102.755C102.755 -2.30756 92.9485 -4.72029 89.5542 1.75985L93.0976 3.61583Z" fill="url(#paint0_linear_1_1463)" mask="url(#path-1-inside-1_1_1463)"/>
</g>
<defs>
<linearGradient id="paint0_linear_1_1463" x1="85.7633" y1="0" x2="85.7633" y2="335.423" gradientUnits="userSpaceOnUse">
<stop stop-color="#23AAE1"/>
<stop offset="1" stop-color="#7723E1"/>
</linearGradient>
</defs>
</svg>
);
}

export default svg;
