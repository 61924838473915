import React from 'react';
import '../App.css';
import JohnD from '../assets/johnd.png' 

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
   //
  }


  componentWillUnmount() {
  }
  render() {
    return (

   this.props.mobile ? 


    <div style={{marginTop:'80px'}}>
      <div style={{textAlign:'left',paddingBottom:'30px'}}>
         <span style={{fontSize:'30px',fontWeight:'600'}}> Don't Just Take Our Words... </span> <br/> <br/>
         <span style={{color: '#686868',fontSize: '20px',fontWeight:'400',lineHeight:'30.7px'}}>
            Don't just take our word for it, see what others are saying. Become a member today and elevate your electric vehicle charging experience.
          </span>  
      </div>
      <div style={{display:'block'}}>
        <div style={{padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF',marginBottom:'20px'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF',marginBottom:'20px'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF',marginBottom:'20px'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF',marginBottom:'20px'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF',marginBottom:'20px'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF',marginBottom:'20px'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
     </div>
   </div>
:


    <div style={{marginTop:'80px'}}>
      <div style={{textAlign:'left',width:'800px',paddingBottom:'30px'}}>
         <span style={{fontSize:'56px',fontWeight:'600'}}> Don't Just Take Our Words... </span> <br/> <br/>
         <span style={{color: '#686868',fontSize: '24px',fontWeight:'400',lineHeight:'30.7px'}}>
            Don't just take our word for it, see what others are saying. Become a member today and elevate your electric vehicle charging experience.
          </span>  
      </div>
      <div style={{display:'flex',flexDirection:'row'}}>
        <div style={{flex:10,padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{flex:1}}> </div>
        <div style={{flex:10,padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{flex:1}}> </div>
        <div style={{flex:10,padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
      </div>
      <div style={{display:'flex',flexDirection:'row',marginTop:'24px'}}>
        <div style={{flex:10,padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{flex:1}}> </div>
        <div style={{flex:10,padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
        <div style={{flex:1}}> </div>
        <div style={{flex:10,padding:'24px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#FFF'}}>
          <span style={{fontSize:'18px',fontWeight:'400',color:'#696969'}}> Gridspot has revolutionized the way I charge my electric vehicle. I’ve had a fantastic experience sharing my charger with others and earning rewards in the process. </span> <br/><br/>
          <img src={JohnD}/>
          <span style={{fontSize:'24px',color:'black',fontWeight:'600'}}> John D </span> 
        </div>
      </div>

    </div>


    )
  }
}

export default Testimonials;
