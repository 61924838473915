import React from 'react';

const svg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="plus">
    <path id="Icon" d="M12 5V19M5 12H19" stroke="#23AAE1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
  );
}

export default svg;

