import React from 'react';
import '../App.css';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {expanded:false, headerSize : this.props.mobile ? '9px' : '18px', regSize : this.props.mobile ? '8px' : '13px'};
  }

  componentDidMount() {
   //
  }

  componentWillUnmount() {
  }
  render() {
    return (
   <div style={{backgroundColor:"#1e2426",width:'100%',marginTop:'66px',display:'block'}}>
    <div style={{backgroundColor:"#1e2426",width:'100%',paddingBottom:'20px',paddingRight:'5%',paddingLeft:'5%',paddingTop:'50px',display:'flex',flexDirection:'row'}}>        
       <div style={{display:'block',flex:this.props.mobile ? '2' : '4'}}>
          <span style={{color:"#7bcbed",fontFamily:'Helvetica',fontSize:this.props.mobile ? '12px' : '24px',fontWeight:'400',lineHeight:'120%'}}> Gridspot </span><br/> <br/>
          <span style={{color:"white",fontFamily:'Helvetica',fontSize: this.props.mobile ? '8px' : '24px',fontWeight:'400',lineHeight:'120%'}}> 
            The Convenient Solution for <br/>  Keeping Your EV Powered.
          </span>
        </div>
        <div style={{flex:'1',color:'#d2d3d4',textAlign:'left'}}> 
          <span style={{fontSize:this.state.headerSize,color:'white'}}>  Company </span> <br/><br/>
          <span onClick={event =>  window.location.href='https://www.gridspot.co/blog'} style={{cursor:'pointer',fontSize:this.state.regSize}}> Blog </span> <br/><br/>
          <span style={{fontSize:this.state.regSize}}>  Careers </span> <br/><br/>
        </div>
        <div style={{flex:'1',color:'#d2d3d4',textAlign:'left'}}> 
          <span style={{fontSize:this.state.headerSize,color:'white'}}>  Resources </span> <br/><br/>
          <span style={{fontSize:this.state.regSize}}>  Documentation </span> <br/><br/>
          <span  onClick={s=>this.props.pageChanged('contact')} style={{fontSize:this.state.regSize,cursor:'pointer'}} >  Contact Us </span><br/><br/>
          <span style={{fontSize:this.state.regSize}}>  Press Conferences </span> <br/><br/>
        </div>
        <div style={{flex:'1',color:'#d2d3d4',textAlign:'left'}}> 
          <span style={{fontSize:this.state.headerSize,color:'white'}}>  Legal </span> <br/><br/>
          <span onClick={event =>  window.location.href='https://www.gridspot.co/nondiscrimination'} style={{cursor:'pointer',fontSize:this.state.regSize}}>  Non-Discrimination Policy </span> <br/><br/>
          <span onClick={event =>  window.location.href='https://www.gridspot.co/privacy'} style={{fontSize:this.state.regSize,cursor:'pointer'}}> Privacy Policy </span> <br/><br/>
          <span onClick={event =>  window.location.href='https://www.gridspot.co/terms'} style={{cursor:'pointer',fontSize:this.state.regSize}}> Terms of Service </span> <br/><br/>
        </div>
    </div>        
      <div style={{paddingLeft:'5%',paddingRight:'5%',backgroundColor:'#1e2426'}}> <hr style={{backgroundColor:"#666a6b",marginTop:'-10px',height:'1px'}}/> <br/> <br/>
       <span style={{color:'#d2d3d4'}}>  © 2023 Gridspot. All rights reserved. </span> <br/><br/><br/>
     </div>
</div>
    )
  }
}

export default Footer;
