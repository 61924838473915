import React from 'react';

const svg = () => {
   return (
<svg width="579" height="1781" viewBox="0 0 579 1781" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="Oval" opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M-1032.14 1411.13C-744.755 1809.56 -188.794 1899.57 209.631 1612.19C608.057 1324.8 698.072 768.839 410.685 370.413C123.299 -28.0123 -432.661 -118.027 -831.087 169.359C-1229.51 456.745 -1319.53 1012.71 -1032.14 1411.13ZM-788.831 1235.63C-979.291 971.581 -919.635 603.129 -655.586 412.669C-391.537 222.209 -23.0844 281.864 167.376 545.914C357.836 809.963 298.18 1178.42 34.1306 1368.88C-229.919 1559.34 -598.371 1499.68 -788.831 1235.63Z" fill="url(#paint0_linear_1_1448)" fill-opacity="0.4"/>
<defs>
<linearGradient id="paint0_linear_1_1448" x1="410.685" y1="370.413" x2="-1032.14" y2="1411.13" gradientUnits="userSpaceOnUse">
<stop stop-color="#23AAE1"/>
<stop offset="1" stop-color="#7723E1"/>
</linearGradient>
</defs>
</svg>
);
}

export default svg;
