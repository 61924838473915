import React from 'react';
import GSLogo from '../assets/GSLogo.png'
import Oval0 from '../assets/oval0'
import Oval from '../assets/oval'
import Bolt from '../assets/bolt'
import '../App.css';
import {redirectToStore} from './Helpers'

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading:true};
  }

  componentDidMount() {
  }


  componentWillUnmount() {
  }

  render() {
    return (
   <div style={{display:'block'}}>
     <div id="howcontainer" style={{height:'1650px'}}>
       <div id="howunder" style={{height:'1650px'}}>
          <div style={{display:'flex',justifyContent:'space-evenly'}}>
             <div style={{flex:1,width:'100px',paddingTop:'100px'}}>
                <Oval0/>      
             </div>     
              <div style={{flex:1,width:'100px',paddingTop:'140px'}}>
                <center>   <Bolt/>     </center> 
             </div>     
              <div style={{flex:1,width:'100px'}}>
                <Oval/>      
             </div>     
          </div> 
       </div>
         <div id="howover" style={{height:'150px'}}>
           <div style={{display:'block',justifyContent:'space-evenly',alignItems:'stretch',height:'150x',marginTop:'20px'}}>
              <div style={{flex:'1',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                 <div style={{flex:1,justifyContent:'left',textAlign:'left',paddingLeft:'10px'}}>
                   <img src={GSLogo} width='150px'/>
                 </div> 
                 <div style={{flex:3,marginTop:'4px'}}>
                    <center> 
                       <div class="header-menu" style={{background:'#FFF',boxShadow:'0px 4px 20px 0px rgba(0, 0, 0, 0.05) inset', color:'black',borderRadius:'64px',border:'2px solid rgba(0, 0, 0, 0.10)'}}>
                         <div style={{cursor:'pointer'}} onClick={e=>this.props.pageChanged('main')}>
                           Home
                        </div>
                        <div style={{cursor:'pointer'}} onClick={e=>this.props.pageChanged('how')}>
                            How It Works 
                        </div>  
                        <div style={{color:'#23AAE1'}} onClick={e=>this.props.pageChanged('contact')}>
                          Contact
                        </div> 
                      </div>
                   </center>
                 </div>
                 <div style={{flex:'1',paddingRight:'20px',textAlign:'right'}}>
                    <div class="download-button" onClick={e=>redirectToStore()} style={{backgroundColor:'#23AAE1'}}> Download App </div>
                 </div>      
              </div> 
                   <div style={{textAlign: 'center',fontFamily: 'Helvetica',fontSize: '56px',fontWeight: '400',lineHeight:'130%',marginTop:'70px'}}>
                       Contact Us
                   </div>
        <center>
             <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdrRGpfetiP-T141gGuFfW7q26JrpRKB1eSneUk7iFUk-a-Pg/viewform?embedded=true" width="640" height="1526" frameborder="0" marginheight="0" marginwidth="0" onLoad={e=>this.setState({loading:false})}>   �</iframe>
        </center>

             </div> 
         </div>
      </div> 

   </div>
    )
  }
}

export default ContactForm;
