import React from 'react';
import AppImages1 from '../assets/appImages1.png';
import Technology from '../assets/technology.png';
import Sharing from '../assets/sharing.png';
import Network from '../assets/network.png';
import Membership from '../assets/membership.png';
import '../App.css';

let why1Headline = "Cost-Free Installation";
let why1 = "Gridspot provides a no-cost installation of EV chargers and takes care of all maintenance and repairs, so you can offer EV charging without the upfront investment at a fixed monthly cost.";
let why2Headline = "Hands-Off Management";
let why2 = "We handle all aspects of charger operation, from monitoring and repairs to upgrades, ensuring reliable performance with no effort required from property managers."
let why3Headline = "Enhanced Tenant Satisfaction";
let why3 = "Offering EV charging as an amenity meets the needs of modern, eco-conscious tenants, making your property more attractive and competitive in the market."
let why4Headline = "Predictable, Fixed Monthly Pricing"
let why4 = "With a simple, fixed monthly rate, Gridspot allows you to budget confidently, knowing exactly what you’re paying each month with no surprises or variable costs."

class WhatWhy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {whatText:"Gridspot is your trusted partner in EV charging, offering comprehensive products and services tailored to meet the needs of multifamily and mixed-use properties. From installation to maintenance and repair, we ensure your EV chargers are always operational and dependable. Our innovative, cost-effective solutions are designed to make EV charging hassle-free, allowing property owners to enhance tenant satisfaction and attract eco-conscious residents. With Gridspot, you gain a reliable partner dedicated to making EV charging infrastructure accessible, functional, and stress-free."}

  }

  componentDidMount() {
   //
  }


  componentWillUnmount() {
  }

  render() {
    return (

   this.props.mobile ? 
 
    <div>
      <div style={{display:'block',paddingTop:'0px'}}> 
        <div style={{paddingTop:'40px',textAlign:'left'}}>
           <span style={{fontSize:'30px',fontWeight:'600',textAlign:'left'}}> What Is Gridspot? </span> <br/> <br/><br/><br/>
           <span style={{color: '#686868',fontSize: '20px',fontWeight:'400',lineHeight:'30.7px'}}>
	     {this.state.whatText}
            </span> 
        </div> <br/><br/>
        <div style={{}}>
          <center>  <img src={AppImages1} style={{objectFit:'contain',height:'60%',width:'60%'}}/>  </center>
        </div> <br/><br/><br/>
      </div> 
      <div style={{textAlign:'left',width:'100%',paddingBottom:'30px'}}>
      <span style={{fontSize:'30px',fontWeight:'600'}}> Why Gridspot? </span> <br/> <br/>
       <span style={{color: '#686868',fontSize: '20px',fontWeight:'400',lineHeight:'30.7px'}}>
          Choose Gridspot for seamless access to a widespread network of car chargers via our convenient membership program.
       </span>  
       </div> 
      <div style={{display:'block',justifyContent:'center'}}> 
          <center>
          <div class="whyboxmobile">
           <img src={Membership} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'20pt',fontWeight:'500'}}>  {why1Headline} </span> <br/>
             <span style={{color:'#696969',fontSize:'16pt'}}>
       {why1}
             </span>
          </div><br/>
          <div class="whyboxmobile">
           <img src={Network} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'20pt',fontWeight:'500'}}>  {why2Headline} 
 </span> <br/>
           <span style={{color:'#696969',fontSize:'16pt'}}>
        {why2}
           </span>
          </div> <br/>
          <div class="whyboxmobile">
           <img src={Sharing} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'20pt',fontWeight:'500'}}>  {why3Headline} </span> <br/>
             <span style={{color:'#696969',fontSize:'16pt'}}>
            {why3}
             </span>
          </div> <br/>
          <div class="whyboxmobile">
           <img src={Technology} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'20pt',fontWeight:'500'}}>  {why4Headline} </span> <br/>
           <span style={{color:'#696969',fontSize:'16pt'}}>
         {why4}
	    </span>
          </div><br/>
          </center>
       </div>
     </div>
   
   :


    <div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',maxWidth:'2400px',height:'1000px',paddingTop:'80px'}}>
        <div style={{flex:'1',paddingTop:'150px',textAlign:'left'}}>
           <span style={{fontSize:'58px',fontWeight:'600',textAlign:'left'}}> What Is Gridspot? </span> <br/> <br/><br/><br/>
           <span style={{color: '#686868',fontSize: '27px',fontWeight:'400',lineHeight:'30.7px'}}>
	      {this.state.whatText}
            </span> 
        </div>
        <div style={{flex:'1',justifyContent:'right',textAlign:'right'}}>
           <img src={AppImages1} style={{objectFit:'contain',height:'100%',width:'100%'}}/> 
        </div>
      </div>
      <div style={{textAlign:'left',width:'100%',paddingBottom:'30px',marginTop:'40px'}}>
      <span style={{fontSize:'56px',fontWeight:'600'}}> Why Gridspot? </span> <br/> <br/>
       <span style={{color: '#686868',fontSize: '27px',fontWeight:'400',lineHeight:'30.7px'}}>
          Choose Gridspot for seamless access to a widespread network of car chargers via our convenient membership program.
       </span>  
       </div>
      <div style={{width:'100%',flexWrap:'wrap',display:'flex',justifyContent:'center'}}> 
          <div class="whybox">
           <img src={Membership} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'25pt',fontWeight:'500'}}>  {why1Headline} </span> <br/>
             <span style={{color:'#696969',fontSize:'20pt'}}>
             {why1}
             </span>
          </div>
          <div style={{flex:1}}> </div>
          <div class="whybox">
           <img src={Network} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'25pt',fontWeight:'500'}}>  {why2Headline} </span> <br/>
           <span style={{color:'#696969',fontSize:'20pt'}}>
           {why2}
           </span>
          </div>
      </div> <br/><br/><br/>
       <div style={{width:'100%',flexWrap:'wrap',display:'flex',justifyContent:'center'}}> 
          <div class="whybox">
           <img src={Sharing} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'25pt',fontWeight:'500'}}>  {why3Headline} </span> <br/>
             <span style={{color:'#696969',fontSize:'20pt'}}>
          {why1}
             </span>
          </div>
          <div style={{flex:1}}> </div>
          <div class="whybox">
           <img src={Technology} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'25pt',fontWeight:'500'}}>  {why4Headline}  </span> <br/>
           <span style={{color:'#696969',fontSize:'20pt'}}>
           {why4}
           </span>
          </div>
      </div>

     </div>
    )
  }
}

export default WhatWhy;
