import React from 'react';
import '../App.css';
import GoogleStore from '../assets/googbadge.svg'
import AppleStore from '../assets/appbadge.svg'
import RegisterScreen from '../assets/registerscreen.png'
import CarChgNight from '../assets/carchgnight.png'
import MapWPins from '../assets/mapwpins.png'
import {redirectToStore} from './Helpers'

class LetsStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
   //
  }


  componentWillUnmount() {
  }
  render() {
    return (
   
    this.props.mobile ? 

    <div style={{marginTop:'80px'}}>
        <div style={{borderRadius: '16px',backgroundColor:'#23AAE1',padding:'24px',color:'white',marginBottom:'20px'}}>

         <span style={{fontSize:'32px',lineHeight:'normal'}}> Reserve A Charger Directly From Our Map:  Easy And Fast!  </span> <br/><br/>
         <span style={{fontSize:'18px'}}> 
          Locate a charger with ease and speed using the Gridspot map. Park as close as possible to your desired location and reserve as much charge/parking time as you need.  No more worries about broken or occupied chargers. Take the guesswork out of EV charging with Gridspot. </span> <br/><br/>
          <center>  <div class="download-button" onClick={e=>redirectToStore()}> Download App </div>  </center>
             <center> <div style={{marginTop:'30px'}}> <img src={MapWPins} style={{obj1ctFit:'contain',height:'100%',width:'100%',borderRadius:'8px',border:'3px solid rgba(0, 0, 0, 0.20)'}}/>  </div>  </center>
        </div> 
        <div style={{flex:'10',borderRadius: '16px',backgroundColor:'#323232',color:'white',padding:'24px',marginBottom:'20px'}}>

         <span style={{fontSize:'32px'}}> Rent Your Charger To Other Users: Easy Income!  </span> <br/><br/>
         <span style={{fontSize:'18px'}}>
           Unlock a source of easy income by renting out your charger with Gridspot. Sharing your charger is not only convenient but also a rewarding way to generate income effortlessly. Embrace the opportunity to contribute to sustainable transportation while earning from your idle charger! </span> <br/><br/>

             <center> <div class="download-button" onClick={e=>redirectToStore()}> Download App </div>  </center>

             <center> <div style={{marginTop:'30px'}}> <img src={CarChgNight} style={{obj1ctFit:'contain',height:'100%',width:'100%',borderRadius:'8px',border:'3px solid rgba(0, 0, 0, 0.20)'}}/>  </div>  </center>
    </div>
   </div>
   


    :


    <div style={{marginTop:'80px',display:'flex',flexDirection:'row'}}>
        <div style={{flex:'10',borderRadius: '16px',backgroundColor:'#23AAE1',padding:'24px',color:'white'}}>

         <span style={{fontSize:'32px',lineHeight:'normal'}}> Reserve A Charger Directly From Our Map:  Easy And Fast!  </span> <br/><br/>
         <span style={{fontSize:'18px'}}> 
          Locate a charger with ease and speed using the Gridspot map. Park as close as possible to your desired location and reserve as much charge/parking time as you need.  No more worries about broken or occupied chargers. Take the guesswork out of EV charging with Gridspot. </span> <br/><br/>
             <div class="download-button" onClick={e=>redirectToStore()}> Download App </div> 
             <center> <div style={{marginTop:'60px'}}> <img src={MapWPins} style={{obj1ctFit:'contain',height:'100%',width:'100%',borderRadius:'8px',border:'3px solid rgba(0, 0, 0, 0.20)'}}/>  </div>  </center>
        </div> 
        <div style={{flex:'1'}}> </div>
        <div style={{flex:'10',borderRadius: '16px',backgroundColor:'#323232',color:'white',padding:'24px'}}>

         <span style={{fontSize:'32px',lineHeight:'normal'}}> Rent Your Charger To Other Users: Easy Income!  </span> <br/><br/>
         <span style={{fontSize:'18px'}}>
           Unlock a source of easy income by renting out your charger with Gridspot. Sharing your charger is not only convenient but also a rewarding way to generate income effortlessly. Embrace the opportunity to contribute to sustainable transportation while earning from your idle charger! </span> <br/><br/>

             <div class="download-button" onClick={e=>redirectToStore()}> Download App </div> 

             <center> <div style={{marginTop:'60px'}}> <img src={CarChgNight} style={{obj1ctFit:'contain',height:'100%',width:'100%',borderRadius:'8px',border:'3px solid rgba(0, 0, 0, 0.20)'}}/>  </div>  </center>
    </div>
   </div>
    )
  }
}

export default LetsStart;
