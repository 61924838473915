import React from 'react';
import '../App.css';
import Plus from '../assets/plus'
import Minus from '../assets/minus'

class QuestionBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {expanded:false};
  }

  componentDidMount() {
   //
  }


  expandClicked = () => {
    this.setState({expanded:!this.state.expanded})
  }

  componentWillUnmount() {
  }
  render() {
    return (
       <div style={{flex:'1',marginBottom:'18px',borderRadius:'24px',border: '1.5px solid #23AAE1',background: 'rgba(35, 170, 225, 0.02)',padding:'40px'}} onClick={e=>this.expandClicked()} >
         <div style={{display:'flex',flexDirection:'row',justifyContents:'space-between',width:'100%'}}>
            <div style={{flex:10}}>
                <span style={{color:'#070707',fontSize: this.props.mobile ? '16px' : '20px',fontFamily: 'Plus Jakarta Sans',fontWeight:'400',lineHeight:'180%',textTransform:'capitalize'}}>  {this.props.question} </span>
            </div>
            <div style={{flex:1,textAlign:'right'}}>
            <span style={{color:'#070707',fontSize: this.props.mobile ? '14px' : '18px',fontFamily: 'Plus Jakarta Sans',fontWeight:'400',lineHeight:'180%',textTransform:'capitalize'}}>  
                   {this.state.expanded ? <Minus/> : <Plus/>}
             </span>
             </div>
            </div>

           {this.state.expanded && <div style={{marginTop:'24px',fontSize:this.props.mobile ? '14px' : '20px',fontFamily: 'Plus Jakarta Sans',fontWeight:'500',lineHeight:'28px',color:"#8c8c8c"}}>  {this.props.answer}  </div> }
         </div>        
    )
  }
}

export default QuestionBox;
