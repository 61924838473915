import React from 'react';

const svg = () => {
  return (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="minus">
    <path id="Icon" d="M5 12.5H19" stroke="#23AAE1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>

  );
}

export default svg;

